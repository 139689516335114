<template>
  <main class="bg-light">
    <div>
      <keep-alive>
        <top-nav/>
      </keep-alive>
    </div>
    <div>
      <router-view/>
    </div>
    <b-container class="mt-4">
      <b-row>
        <b-col v-if="pending">
        </b-col>
        <b-col v-else>
          <b-table-simple small responsive="" bordered class="bg-white">
            <b-thead>
              <b-tr>
                <b-th>시작 날짜</b-th>
                <b-th>마지막 날짜</b-th>
                <b-th>제목</b-th>
                <b-th>내용</b-th>
                <b-th>작성자</b-th>
                <b-th>작성일시</b-th>
                <b-th>사용여부</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="list.length > 0">
              <b-tr
                  v-for="event in list"
                  :key="`event-${event._id}`"
                  :class="selectedId === event._id ? 'active' : ''"
              >
                <b-td>
                  {{event.date.start}}
                </b-td>
                <b-td>
                  {{event.date.end}}
                </b-td>
                <b-td>
                  <div class="d-block" style="max-width: 200px;">
                    <p class="text-truncate my-1">
                      <router-link
                          :to="`/pointEvent/${event._id}/users`"
                      >
                        {{ event.title }}
                      </router-link>
                    </p>
                  </div>
                </b-td>
                <b-td>
                  <div class="d-block" style="max-width: 200px;">
                    <p class="text-truncate my-1">
                      {{ event.content }}
                    </p>
                  </div>
                </b-td>
                <b-td>
                  {{ event.regUserId }}
                </b-td>
                <b-td>
                  {{ new Date(event.regDate).toLocaleString() }}
                </b-td>
                <b-td>
                  {{event.eventUse ? '이벤트 중' : '이벤트 종료'}}
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td colspan="7">포인트 이벤트가 존재하지 않습니다.</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div class="d-flex justify-content-end mb-2">
            <b-button
                to="/pointEvent/new"
                variant="danger"
                size="sm"
                squared
            >
              <font-awesome-icon icon="plus"/> 등록
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination-nav
              v-if="list.length"
              :link-gen="linkGen"
              v-model="page"
              :number-of-pages="perPage"
              use-router
              aria-controls="my-table"
              first-number
              last-number
              first-text="«"
              prev-text="‹"
              next-text="›"
              last-text="»"
              align="right"
              size="sm"
          />
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
export default {
  name: "pointEvent",
  data: () => ({
    pending: false,
    page: 1,
    total: 0,
    perPage: 0,
    list: [],
    selectedId: null,
    filterData: []
  }),
  created() {
    const {_id} = this.$route.params
    if (_id) this.selectedId = _id
    const {page} = this.$route.query
    if (page && !isNaN(+page)) this.page = +page
    this.load(this.page)
  },
  methods: {
    async load (pageNo) {
      try {
        this.page = pageNo ? isNaN(+pageNo) ? 1 : +pageNo : 1
        const params = {
          page: this.page
        }

        const {data} = await this.axios({
          url: `/admin/point/event`,
          params
        })

        const {result, list, page, total, totalPage} = data

        if (result) {
          this.list = list
          this.page = page
          this.total = total
          this.perPage = totalPage
        }
      } catch (e){}
    },
    linkGen (page) {
      return `${this.$route.path}?page=${page}&${Object.entries(this.filterData).map(v => v[1] ? `${v[0]}=${v[1]}` : `${v[0]}`).join('&')}`
    },
  }
}
</script>

<style scoped>

</style>
